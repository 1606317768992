"use client"

import { useState } from "react"
import { X } from "lucide-react"
import { motion } from "framer-motion"
import { useTranslation } from 'react-i18next';

const PortableCarInspectionDialog = () => {
    const [isOpen, setIsOpen] = useState(true)
    const { t } = useTranslation();

    if (!isOpen) return null

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                transition={{ duration: 0.3 }}
                className="bg-white p-8 rounded-3xl shadow-2xl w-[400px] relative"
            >
                <motion.p
                    className="absolute top-2 left-2 text-xs font-mono text-gray-500"
                    animate={{ opacity: [1, 0, 1] }}
                    transition={{ duration: 1, repeat: Infinity }}
                >
                </motion.p>
                <button
                    className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 transition-colors"
                    onClick={() => setIsOpen(false)}
                >
                    <X size={24} />
                </button>
                <div className="flex items-center mb-6">
                    <img
                        src="https://cdn-icons-png.freepik.com/256/11195/11195153.png?semt=ais_hybrid"
                        alt="Car Inspection"
                        className="w-16 h-16 mr-4"
                    />
                    <h2 className="text-2xl font-bold text-gray-800">{t('portable_inspection_title')}</h2>
                </div>
                <p className="text-gray-600 mb-6 leading-relaxed">
                    {t('portable_inspection_description')}
                </p>
                <div className="flex flex-col gap-4">
                    <motion.a
                        href="https://wa.me/971561868236"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-cyan-500 text-white py-3 px-6 rounded-lg text-center font-semibold hover:bg-cyan-600 transition-colors"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        animate={{
                            boxShadow: [
                                "0px 0px 0px 0px rgba(0, 200, 255, 0.4)",
                                "0px 0px 20px 10px rgba(0, 200, 255, 0.2)",
                                "0px 0px 0px 0px rgba(0, 200, 255, 0.4)",
                            ],
                        }}
                        transition={{
                            boxShadow: {
                                duration: 2,
                                repeat: Number.POSITIVE_INFINITY,
                                repeatType: "reverse",
                            },
                        }}
                    >
                        {t('start_service')}
                    </motion.a>
                    <button
                        className="bg-gray-100 text-gray-700 py-3 px-6 rounded-lg hover:bg-gray-200 transition-colors"
                        onClick={() => setIsOpen(false)}
                    >
                        {t('close')}
                    </button>
                </div>
            </motion.div>
        </div>
    )
}

export default PortableCarInspectionDialog
